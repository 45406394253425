import styled from 'styled-components';
import type { ReactNode, FC } from 'react';

/**
 * Applies continuous numbering to nested ol/li elements, e.g.
 *
 * Usage: Wrapping this component around one or more `NumberedList`/`NumberedListNested` will
 * ensure numbering does not reset to 1 in each component
 */
const CounterContainer = styled.div`
  counter-reset: item;
`;

interface NumberedListGroupProps {
  children: ReactNode[] | ReactNode;
}

export const NumberedListGroup: FC<NumberedListGroupProps> = ({ children }) => (
  <CounterContainer>{children}</CounterContainer>
);
