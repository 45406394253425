import type { Clause } from 'components/List';

export const referralTermsContent: Clause[] = [
  {
    id: '1',
    content: `The Sharesight Referral Program, also known as Refer a Friend, (“Program”) offered by Sharesight Pty Ltd or Sharesight Limited (“Sharesight”) has been created to reward you (“you”, “your” or “referrer”), our loyal customers for doing what you already do, recommending Sharesight to friends and family who sign-up to become Sharesight customers (“referee”). The Program was also created to reward your friends and family for listening to your recommendation by offering them a reward for signing up to Sharesight.`,
  },
  {
    id: '2',
    content: `These Program Terms and Conditions are a binding agreement between you and Sharesight and will govern your participation in any and all Program offers. You are not authorised to participate in the Program if you do not agree to these Program Terms and Conditions in their entirety.`,
  },
  {
    id: '3',
    content: `A referral is successful when the referee signs-up to Sharesight within seven days of clicking on the referral link that you provided and confirms their email address.`,
  },
  {
    id: '4',
    content: `Sharesight rewards you for each successful referral.`,
  },
  {
    id: '5',
    content: `To participate in the Program you must refer a person to Sharesight by providing that person with your unique referral link as displayed on the referrals page within Sharesight.`,
  },
  {
    id: '6',
    content: `For each successful referral, you are rewarded with a Sharesight credit to the value displayed on the referrals page within Sharesight.`,
  },
  {
    id: '7',
    content: `For each successful referral, the referee shall be issued with a coupon which entitles them to a discount on a paid subscription, as specified on the change plans page within Sharesight. The coupon is valid for three months from the date that the referee signed up.`,
  },
  {
    id: '8',
    content: `Sharesight credits are used to reduce your monthly or yearly invoice. An invoice cannot be reduced below $0.`,
  },
  {
    id: '9',
    content: `Sharesight credits can not be redeemed for cash or any other form of payment and are non-transferable.`,
  },
  {
    id: '10',
    content: `Sharesight credits will be lost when you cancel your Sharesight account.`,
  },
  {
    id: '11',
    content: `Unused sharesight credits will expire after two years.`,
  },
  {
    id: '12',
    content: `If the referee is already a Sharesight user then neither you or the referee are entitled to a referral reward.`,
  },
  {
    id: '13',
    content: `Referrals generated by a script, macro, robot or any other automated process shall be voided unless previously agreed to in writing by Sharesight.`,
  },
  {
    id: '14',
    content: `Sharesight accepts no responsibility for referrals lost or delayed in transit or due to an error on your personal computer or mobile device.`,
  },
  {
    id: '15',
    content: `Referral rewards may be taxable, depending on the federal, state, and local tax laws applicable to you or the referee. You are solely responsible for reporting such items on your tax returns and paying any associated tax liability. You are responsible for getting your own tax advice.`,
  },
  {
    id: '16',
    content: `Sharesight reserves the right to amend or alter this offer at any time.`,
  },
  {
    id: '17',
    content: `Sharesight reserves the right to void any Sharesight credits and/or coupons that arise from any behaviour that is not within the spirit of the Program. An example of such behaviour includes any distribution of your referral link in a way that could constitute unsolicited commercial email or “spam” under any applicable law or regulation.`,
  },
];
