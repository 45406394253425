import styled from 'styled-components';
import { numberedListItemMixin } from 'styled/mixins/numberedListItemMixin';

import type { Dimension, StyledProps } from 'styled/types';

type ListProps = StyledProps & { itemGap: Dimension };

export const HeaderList = styled.ol`
  padding-left: 0;
  margin: 0;
`;

export const ClauseList = styled.ol`
  counter-reset: item;
  margin-left: -8.9rem;
`;

export const SubclauseList = styled.ol`
  counter-reset: item;
  margin-left: -6rem;
`;

export const HeaderItem = styled.li<ListProps>`
  ${props =>
    numberedListItemMixin({
      theme: props.theme,
      itemGap: props.itemGap,
      numberStyle: 'header',
    })}
`;

export const ClauseItem = styled.li<ListProps>`
  ${props =>
    numberedListItemMixin({
      theme: props.theme,
      itemGap: props.itemGap,
      numberColor: 'grey',
    })}
`;

export const SubclauseItem = styled.li<ListProps>`
  ${props =>
    numberedListItemMixin({
      theme: props.theme,
      itemGap: props.itemGap,
      numberStyle: 'alpha',
      numberColor: 'grey',
    })}
`;
