import { format as formatDate } from 'fecha';
/**
 * Returns a string representing a Date in the form
 * <date><ordinal> <full-month-name> <full-year>
 *
 * e.g. `new Date('1999-09-21')` returns `'21st September 1999'`
 */
export const getDateString = (dateString: string | Date, format?: string): string => {
  const passedDate = new Date(dateString);
  const dateFormat = format || 'Do MMMM YYYY';

  return formatDate(passedDate, dateFormat);
};
