import { css } from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

import type { Color, Dimension, Theme } from 'styled/types';

export interface MixinProps {
  theme: Theme;
  itemGap?: Dimension;
  numberStyle?: 'header' | 'number' | 'alpha';
  numberColor?: Color;
  numberPadding?: Dimension;
}

export const numberedListItemMixin = ({
  theme,
  itemGap = 'size2',
  numberStyle = 'number',
  numberColor = 'black',
  numberPadding = 'size4',
}: MixinProps) => css`
  counter-increment: item;
  display: table;

  margin-top: ${getDimension(itemGap)};

  :before {
    display: table-cell;
    text-align: right;
    width: ${getDimension('size10')};
    content: counters(item, '.') '. ';

    ${numberStyle === 'header' &&
    css`
      font-family: ${props => props.theme.fontFamily.financier};
      font-weight: ${props => props.theme.fontWeight.semiBold};
      font-size: ${props => props.theme.fontSize.xl};

      ${theme.breakpoints.down('md')} {
        font-size: ${props => props.theme.fontSize.lg};
      }
    `}

    ${numberStyle === 'alpha' &&
    css`
      content: counter(item, lower-alpha) '. ';
    `}

    color: ${theme.colors[numberColor]};
    padding-right: ${getDimension(numberPadding)};
  }
`;
