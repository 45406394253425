import { Seo } from 'components/Seo';
import { List } from 'components/List';
import { Layout } from 'components/Layout';
import { Container, HeroContainer, Section } from 'components/Container';

import { getDateString } from 'helpers/getDateString';
import { referralTermsContent } from '../page-data/referralTerms';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const lastUpdatedDate = '2022-01-18';

const ReferralTerms: FC<LocalizedPageProps> = () => (
  <Layout>
    <Seo />
    <HeroContainer background="cream">
      <h1>
        Referral Program
        <br />
        Terms and Conditions
      </h1>
    </HeroContainer>
    <Container background="white">
      <Section>
        <List isNumbered noIndent bulletPadding={1} items={referralTermsContent} itemGap={6} />
        <br />
        <p>
          <em>Last updated {getDateString(lastUpdatedDate)}</em>
        </p>
      </Section>
    </Container>
  </Layout>
);

export default ReferralTerms;
