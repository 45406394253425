import { stringToAnchorId } from 'helpers/stringToAnchorId';
import {
  ClauseItem,
  ClauseList,
  HeaderItem,
  HeaderList,
  SubclauseItem,
  SubclauseList,
} from './NumberedListNested.styled';

import type { Dimension } from 'styled/types';
import type { ClauseContent } from './types';
import type { FC } from 'react';

export interface NumberedListNestedProps extends ClauseContent {
  itemGap?: Dimension; // controls spacing between each item
}

/**
 * A numbered list with a header and subclauses.
 *
 * Only displays max of 3 nested levels. i.e.
 *
 * 1. Header
 *   1.1 Clause 1
 *   1.2 Clause 2
 *     a. Subclause 1
 *     b. Subclause 1
 */
export const NumberedListNested: FC<NumberedListNestedProps> = ({
  header,
  clauses,
  itemGap = 'size4',
}) => (
  <HeaderList>
    <HeaderItem itemGap={itemGap} id={stringToAnchorId(header)} aria-label={header}>
      <h2>{header}</h2>
      <ClauseList>
        {clauses.map(clause => (
          <ClauseItem
            itemGap={itemGap}
            key={clause.id}
            id={stringToAnchorId(clause.id)}
            aria-label={clause.id}
          >
            {clause.content}

            {/* Nested subclauses */}
            {clause.subclauses && (
              <SubclauseList>
                {clause.subclauses.map(subclause => (
                  <SubclauseItem
                    itemGap={itemGap}
                    key={subclause.id}
                    id={stringToAnchorId(subclause.id)}
                    aria-label={subclause.id}
                  >
                    {subclause.content}
                  </SubclauseItem>
                ))}
              </SubclauseList>
            )}
          </ClauseItem>
        ))}
      </ClauseList>
    </HeaderItem>
  </HeaderList>
);
